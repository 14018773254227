import moment from "moment";
import "moment/locale/ro";

moment.locale("ro");

export const MonthTimestamp = 3600 * 24 * 31;

export const middleStringEllipsis = (val: string, size = 5): string => {
    return val ? val.substring(0, size) + " ... " + val.slice(-size) : val;
};

export const endStringEllipsis = (val: string, size = 25): string => {
    if (!val || val.length <= size) {
        return val;
    }

    return val.substring(0, size) + "...";
};

export const getDateFromTimestamp = (timestamp: number): string | null => {
    if (!timestamp) {
        return null;
    }

    return moment(timestamp * 1000).format("D MMMM | HH:MM");
};

export function slugify(str: string) {
    return String(str)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const formatNumberWithDecimals = (number: number | string, decimals: number = 2) => {
    const num = Number(number);
    return num.toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
};

export const extractNumber = (string: string) => {
    const numsStr = string.replace(/[^0-9]/g, "");
    return parseInt(numsStr);
};
